import React from 'react';
import MyScatterChart from "./components/MyScatterChart";

function App() {
    return (
        <MyScatterChart/>
    );
}

export default App;
